/* eslint-disable no-unused-expressions */
/* eslint-disable prettier/prettier */
import Auth from '../../../Amplify';
import { ERROR_LOGIN, FIELD_NEXTTIME_LOGIN } from '../../utilities/Constant';

// CONSTANTES
const dataInicial = {};

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGIN_LOADING = 'LOGIN_LOADING';

export const setLoginUser = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export default function loginReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, ...action.payload, errorLogin: null, successLogin: true };
    case LOGIN_ERROR:
      return { ...state, ...action.payload, successLogin: false };
    case LOGOUT_SUCCESS:
      return dataInicial;
    case LOGIN_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

// ACCIONES

export const loginUser = (user, password) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_LOADING,
      payload: true,
    });
    const response = await Auth.signIn(user, password);
    const keyCognitoId = `${response.keyPrefix}.${response.username}.idToken`;
    const idToken = response.storage ? response.storage[keyCognitoId] : null;
    localStorage.setItem('token', idToken);
    localStorage.setItem(FIELD_NEXTTIME_LOGIN, new Date());
    const fullName = `${response?.attributes?.given_name} ${response?.attributes?.middle_name}`;
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { fullName, ...response.attributes, loading: false },
    });
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      payload: { errorLogin: ERROR_LOGIN, loading: false },
    });
  }
};

export const logout = (credentialsUser) => async (dispatch) => {
  try {
    localStorage.clear();
    credentialsUser !== null &&
      localStorage.setItem('credentials', JSON.stringify(credentialsUser));

    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  }
};
