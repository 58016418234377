import React, { useEffect, useState } from 'react';
import { Divider, Progress, Table } from 'antd';
import Proptypes from 'prop-types';

import { pathServer } from '../../utilities/Constant';

import './index.less';

const RowLoading = ({ files }) => {
  const [percentBar, setPercentBar] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (percentBar < 90) {
        setPercentBar(percentBar + 1);
      }
    }, 150);
  }, [percentBar]);

  return (
    <div>
      {files?.map((item) => {
        const columns = [
          {
            title: 'Archivo',
            dataIndex: 'name',
            key: 'name',
            width: 40,
            render: () => <img src={`${pathServer.PATH_IMG}ic_excel.png`} />,
          },
          {
            title: 'Nombre de Archivo',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            render: () => <span className="files-nameLoading">{item.name}</span>,
          },
          {
            title: '% de Carga de Archivo',
            dataIndex: 'period',
            key: 'period',

            render: () => (
              <div className="progressBar-Loading">
                <Progress className="progress-bar" percent={percentBar} />
              </div>
            ),
          },
        ];
        return (
          <>
            <Table
              columns={columns}
              dataSource={[{ ...item }]}
              size="middle"
              scroll={{ x: 'calc(700px)' }}
              className="table-tracking"
              pagination={false}
            />
            <Divider className="divider-loading" />
          </>
        );
      })}
    </div>
  );
};

RowLoading.displayName = 'RowLoading';

RowLoading.defaultProps = {
  files: [],
};

RowLoading.propTypes = {
  files: Proptypes.array,
};

export default RowLoading;
