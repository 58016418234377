/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import FileUploadTemplate from '../../templates/FileUpload/index.';
import {
  head,
  head2,
  failedhead,
  resultFailed,
  filesEmail,
  options,
  initialEmail,
} from './constants';

import FileServices from '../../../domain/usecases/FileServices';
import EmailServices from '../../../domain/usecases/EmailServices';
import { generateFileUrl } from '../../utilities/Functions';

const errorDefault = {
  value: false,
  title: '',
  subtitle: '',
  nameButton: '',
  functionError: () => {},
};
const FileUploadPage = () => {
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [onSuccessModal, setOnSuccessModal] = useState(false);
  const [onListEmailModal, setListEmailModal] = useState(false);
  const [onErrorValidationModal, setOnErrorValidationModal] = useState(errorDefault);

  const [onViewEmailModal, setViewEmailModal] = useState(false);
  const [onEditEmailModal, setEditEmailModal] = useState(false);
  const [onSendEmailModal, setSendEmailModal] = useState(false);
  const [onConfirmUpdateEmailModal, setConfirmUpdateEmailModal] = useState(false);
  const [onUpdateEmailModal, setUpdateEmailModal] = useState(false);
  const [filesUpload, setFilesUpload] = useState([]);
  const [filesIds, setFilesIds] = useState([]);

  const [currentPeriod, setCurrentPeriod] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [onRemoveFile, setRemoveFile] = useState(false);
  const [onErrorFormatFile, setErrorFormatFile] = useState(false);
  const [currentTimeStamp, setCurrentTimeStamp] = useState(1234);

  const onUploadFile = async (file) => {
    const currentTS = Math.round(new Date().getTime() / 1000);
    setCurrentTimeStamp(currentTS);
    await generateFileUrl('upload', file);
    setFilesUpload([{ name: file.name }]);
    setTimeout(() => {
      getAllFiles(currentTS);
    }, 15000);
  };

  const onAnalizeFile = async (ids) => {
    await FileServices.updatePeriod({
      id: filesIds[0],
      Type: currentPeriod,
      Order: currentOrder,
      Year: currentYear,
    });
    const [data, error] = await FileServices.analizeFile(filesIds);
    if (data && error === null) {
      const [dataResult, errorResult] = await FileServices.getResultAnalized(ids);
      return [dataResult, errorResult];
    }
    return [data, error];
  };

  const onGetResultAnalized = async (ids) => {
    const [data, error] = await FileServices.getResultAnalized(ids);
    return [data, error];
  };

  const getAllFiles = async (timeStamp) => {
    const [data, error] = await FileServices.getAllIdsByDate(timeStamp);
    if (data && error === null) {
      setFilesIds([...data]);
    } else {
      showErrorFormatFile(true);
    }
  };

  const showSuccessModal = (value) => {
    setOnSuccessModal(value);
  };
  const showLoadingNotificacion = (value) => {
    setLoadingFiles(value);
  };
  const showErrorValidationModal = (value) => {
    setOnErrorValidationModal({ ...errorDefault, ...value });
  };
  const showListEmailModal = (value) => {
    setListEmailModal(value);
  };
  const showViewEmailModal = (value) => {
    setViewEmailModal(value);
  };
  const showEditEmailModal = (value) => {
    setEditEmailModal(value);
  };
  const showSendEmailModal = (value) => {
    setSendEmailModal(value);
  };
  const showConfirmUpdateEmailModal = (value) => {
    setConfirmUpdateEmailModal(value);
  };
  const showErrorFormatFile = (value) => {
    setErrorFormatFile(value);
  };
  const showUpdateEmailModal = (value) => {
    setUpdateEmailModal(value);
  };
  const showConfirmRemoveFile = (value) => {
    setRemoveFile(value);
  };
  const onUpdateEmail = async (id, fromName, updatedEmail) => {
    const [data, error] = await EmailServices.updateEmail(id, fromName, updatedEmail);
    return [data, error];
  };
  const onSendEmail = async (id, fromName) => {
    const [data, error] = await EmailServices.sendEmail(id, fromName);
    return [data, error];
  };
  const clearDataUpload = () => {
    showConfirmRemoveFile(false);
    setCurrentTimeStamp(1234);
    setFilesIds([]);
    setFilesUpload([]);
  };

  return (
    <div>
      <FileUploadTemplate
        onSuccessModal={onSuccessModal}
        showSuccessModal={showSuccessModal}
        onErrorValidationModal={onErrorValidationModal}
        showErrorValidationModal={showErrorValidationModal}
        loadingFiles={loadingFiles}
        showLoadingNotificacion={showLoadingNotificacion}
        onListEmailModal={onListEmailModal}
        showListEmailModal={showListEmailModal}
        onViewEmailModal={onViewEmailModal}
        showViewEmailModal={showViewEmailModal}
        onEditEmailModal={onEditEmailModal}
        showEditEmailModal={showEditEmailModal}
        onSendEmailModal={onSendEmailModal}
        showSendEmailModal={showSendEmailModal}
        onConfirmUpdateEmailModal={onConfirmUpdateEmailModal}
        showConfirmUpdateEmailModal={showConfirmUpdateEmailModal}
        onUpdateEmailModal={onUpdateEmailModal}
        showUpdateEmailModal={showUpdateEmailModal}
        onSendEmail={onSendEmail}
        head={head}
        head2={head2}
        resultFailed={resultFailed}
        failedhead={failedhead}
        files={filesUpload}
        filesEmail={filesEmail}
        options={options}
        initialEmail={initialEmail}
        onUploadFile={onUploadFile}
        onAnalizeFile={onAnalizeFile}
        onGetResultAnalized={onGetResultAnalized}
        currentPeriod={currentPeriod}
        onChangePeriod={(value) => setCurrentPeriod(value)}
        currentYear={currentYear}
        onChangeCurrentYear={(value) => setCurrentYear(value)}
        currentOrder={currentOrder}
        onChangeOrder={(value) => setCurrentOrder(value)}
        onUpdateEmail={onUpdateEmail}
        showConfirmRemoveFile={showConfirmRemoveFile}
        onRemoveFile={onRemoveFile}
        onErrorFormatFile={onErrorFormatFile}
        showErrorFormatFile={showErrorFormatFile}
        currentTimeStamp={currentTimeStamp}
        clearDataUpload={clearDataUpload}
      />
    </div>
  );
};
export default FileUploadPage;
