import React from 'react';
import './index.less';
import { Typography, Row, Col } from 'antd';
import { CustomIcon } from '../../components/index';

const { Title } = Typography;

const IconDocumentation = () => {
  return (
    <div className="container-iconsDocumentation">
      <Title className="title-iconsDocumentation" level={4}>
        Documentación de íconos
      </Title>
      <Row className="rows-iconsDocumentation">
        <Col className="colTitle-iconsDocumentation col-iconsDocumentation  " span={6}>
          <Title level={5}>Icon</Title>
        </Col>
        <Col className="colTitle-iconsDocumentation col-iconsDocumentation" Col span={6}>
          <Title level={5}>Type</Title>
        </Col>
        <Col className="colTitle-iconsDocumentation col-iconsDocumentation  " span={6}>
          <Title level={5}>Icon</Title>
        </Col>
        <Col className="colTitle-iconsDocumentation col-iconsDocumentation" Col span={6}>
          <Title level={5}>Type</Title>
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" span={6}>
          <CustomIcon type="default" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          default
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="spinner" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          spinner
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" span={6}>
          <CustomIcon type="successfull" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          successfull
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="checkNotification" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          checkNotification
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" span={6}>
          <CustomIcon type="upload" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          upload
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="downloadAttachments" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          downloadAttachments
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="loading" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          loading
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="seeTracking" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          seeTracking
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="trash" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          trash
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="sendEmailTracking" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          sendEmailTracking
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="download" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          download
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="editEmailTracking" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          editEmailTracking
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="alert" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          alert
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="seeEmailTracking" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          seeEmailTracking
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="error" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          error
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="dashboard" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          dashboard
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="send" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          send
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="reports" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          reports
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="cross" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          cross
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="tracking" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          tracking
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="check" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          check
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="uploadFiles" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          uploadFiles
        </Col>
      </Row>
      <Row className="rows-iconsDocumentation">
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="cancel" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          cancel
        </Col>
        <Col className="col-iconsDocumentation" Col span={6}>
          <CustomIcon type="question" />
        </Col>
        <Col className="col-iconsDocumentation" span={6}>
          question
        </Col>
      </Row>
    </div>
  );
};

export default IconDocumentation;
