import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { HeadTable, RowFinishedLoad, DropZone, TitlePage } from '../../../components/index';

import './index.less';

const PSValidatingFiles = ({ failedhead, files, head2, validateFiles }) => {
  return (
    <div className="content-validatingFiles">
      <TitlePage
        title="Archivos con Errores"
        note="* Descarga el archivo para que puedas visualizar los campos con errores resaltados en color naranja"
        className="margin-bottom"
      />
      <HeadTable head={failedhead} files={files} className="margin-bottom" />
      <RowFinishedLoad files={files} failed />
      <DropZone smallDropzone />
      <br />
      <TitlePage
        title="Archivos con Carga Satisfactoria"
        note="* Archivos que no tienen errores en la información"
        className="margin-bottom"
      />
      <HeadTable head={head2} files={files} className="margin-bottom" />
      <RowFinishedLoad files={files} />
      <div className="container-btn">
        <Button className="bn-analysis" onClick={validateFiles}>
          Iniciar el análisis de archivos
        </Button>
      </div>
    </div>
  );
};
PSValidatingFiles.displayName = 'PSValidatingFiles';

PSValidatingFiles.defaultProps = {
  head2: [],
  files: [],
};

PSValidatingFiles.propTypes = {
  head2: PropTypes.array,
  files: PropTypes.array,
};
export default PSValidatingFiles;
