import React from 'react';
import { Typography } from 'antd';
import { pathServer } from '../../utilities/Constant';
import { CustomIcon } from '../../components/index';
import './index.less';

const { Title } = Typography;

const LoadingLogin = () => {
  return (
    <div className="container-loadingLogin">
      <div className="firsSection-loadingLogin">
        <img className="logo-loadingLogin" src={`${pathServer.PATH_IMG}logo_login_medium.png`} />
        <div className="container-title-login">
          <Title className="title-loadingLogin platform-loadingLogin">Plataforma </Title>
          <Title className="title-loadingLogin sucavec-loadingLogin">SUCAVE</Title>
        </div>
        <CustomIcon className="icon-loadingLogin" type="loading" />
        <span className="title-loadingLogin platform-loadingLogin">Un momento por favor…</span>
      </div>
      <div className="secondSection-loadingLogin">
        <img className="img-loadingLogin" src={`${pathServer.PATH_IMG}Capa 2@2x.png`} />
      </div>
    </div>
  );
};
export default LoadingLogin;
