import React from 'react';
import { Progress, Divider, Tooltip, Table } from 'antd';
import Proptypes from 'prop-types';

import { pathServer } from '../../utilities/Constant';
import CustomIcon from '../CustomIcon/CustomIcon';

import './index.less';

const RowFinishedLoad = ({ files, failed, removeFile }) => {
  return (
    <div>
      {files?.map((item) => {
        const columns = [
          {
            title: 'Archivo',
            dataIndex: 'name',
            key: 'name',
            width: 40,
            render: () =>
              failed ? (
                <img src={`${pathServer.PATH_IMG}ic_excel_error.png`} className="icon-excel" />
              ) : (
                <img src={`${pathServer.PATH_IMG}ic_excel_success.png`} className="icon-excel" />
              ),
          },
          {
            title: 'Nombre de Archivo',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            render: () => <span className="files-nameFinishedLoad">{item.name}</span>,
          },
          {
            title: '% de Carga de Archivo',
            dataIndex: 'period',
            key: 'period',

            render: () => (
              <div className="progressBar-finishedLoad">
                <Progress className="progress-bar" percent={100} />
              </div>
            ),
          },
          {
            title: 'Acción',
            dataIndex: 'positive',
            key: 'positive',
            width: 60,
            render: () => (
              <div className="column-finishedLoad">
                {failed && (
                  <img
                    className="iconExcelDownload"
                    src={`${pathServer.PATH_IMG}ic_excelTracking.png`}
                  />
                )}
                <div className="container-iconFinishedLoad" onClick={removeFile}>
                  <CustomIcon type="trash" />
                </div>
                {failed && (
                  <div className="container-iconAlert">
                    <Tooltip
                      placement="bottom"
                      title="Estructura inválida de archivo"
                      overlayClassName="invalid"
                    >
                      <CustomIcon type="alert" />
                    </Tooltip>
                  </div>
                )}
              </div>
            ),
          },
        ];
        return (
          <div className="container-table" key={item.id}>
            <Table
              columns={columns}
              dataSource={[{ ...item }]}
              size="middle"
              scroll={{ x: 'calc(700px)' }}
              className="table-tracking"
              pagination={false}
            />
            <Divider className="divider-finishedLoad" />
          </div>
        );
      })}
    </div>
  );
};

RowFinishedLoad.displayName = 'RowFinishedLoad';

RowFinishedLoad.defaultProps = {
  files: [],
  failed: false,
};

RowFinishedLoad.propTypes = {
  files: Proptypes.array,
  failed: Proptypes.bool,
};

export default RowFinishedLoad;
