import React from 'react';
import { Drawer } from 'antd';
import './index.less';
import WMenu from '../Menu';
import WUserInfo from '../UserInfo/UserInfo';

const WDrawer = ({ collapsed, onChange }) => {
  return (
    <Drawer
      placement="left"
      onClick={() => onChange(false)}
      onClose={() => onChange(false)}
      visible={collapsed}
      className="drawer-container"
      width={300}
      closable={false}
    >
      <WUserInfo />
      <WMenu />
    </Drawer>
  );
};
export default WDrawer;
