import * as axios from 'axios/index';

export function doGet(url, params = {}) {
  const idToken = localStorage.getItem('token') || '';

  try {
    return axios({
      url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        entity: 'FILE',
      },
      data: params,
    });
  } catch (error) {
    return error;
  }
}

export function doGetWithParams(url, params = {}, headerParams = null) {
  const idToken = localStorage.getItem('token') || '';
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`,
  };

  if (headerParams) {
    headers = { ...headers, ...headerParams };
  }
  return axios({
    url,
    method: 'get',
    headers,
    data: params,
  }).catch((error) => {
    console.error('error', error);
  });
}

export function doPost(url, params = {}) {
  const idToken = localStorage.getItem('token') || '';

  try {
    return axios({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${idToken}`,
        entity: 'FILE',
      },
      data: params,
    });
  } catch (error) {
    console.log('errorDoPost', error);
    throw error;
  }
}

export function doPut(url, params = {}) {
  const idToken = localStorage.getItem('token') || '';

  return axios({
    url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${idToken}`,
      entity: 'FILE',
    },
    data: params,
  });
}

export function doDelete(url, params = {}) {
  const idToken = localStorage.getItem('token') || '';

  return axios({
    url,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    data: params,
  });
}
