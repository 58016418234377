import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'antd';
import { isNull } from 'lodash';

import { getListLastYears, isEmptyArray } from '../../../utilities/Functions';
import { annualOptions, biannualOptions, monthlyOptions, quarterlyOptions } from './Constants';

import { RowLoading, RowFinishedLoad, Select } from '../../../components/index';

import './index.less';

const PSLoadingFiles = ({
  files,
  loadingFiles,
  validateFiles,
  currentPeriod,
  onChangePeriod,
  currentYear,
  onChangeCurrentYear,
  currentOrder,
  onChangeOrder,
  optionsPeriod,
  removeFile,
}) => {
  const [optionsOrder, setOptionsOrder] = useState([]);

  useEffect(() => {
    if (currentPeriod) {
      let options = [];
      onChangeOrder(null);
      switch (currentPeriod) {
        case 'Anual':
          options = annualOptions;
          break;
        case 'Semestral':
          options = biannualOptions;
          break;
        case 'Trimestral':
          options = quarterlyOptions;
          break;

        case 'Mensual':
          options = monthlyOptions;
          break;
        default:
          options = [];
          break;
      }
      setOptionsOrder(options);
    }
  }, [currentPeriod]);

  return loadingFiles ? (
    <div className="content-loadingFiles">
      <RowLoading files={files} />
    </div>
  ) : (
    <div className="content-loadingFiles">
      <RowFinishedLoad files={files} removeFile={removeFile} className="margin-bottom" />

      <Row gutter={[24, 16]}>
        <Col span={8}>
          <Select
            placeholder="Seleccione Año"
            options={getListLastYears(2)}
            value={currentYear}
            onSelectValue={onChangeCurrentYear}
          />
        </Col>
        <Col span={8}>
          <Select
            placeholder="Seleccione Periodo"
            options={optionsPeriod}
            value={currentPeriod}
            onSelectValue={onChangePeriod}
          />
        </Col>
        <Col span={8}>
          <Select
            placeholder="Seleccione Orden"
            options={optionsOrder}
            value={currentOrder}
            onSelectValue={onChangeOrder}
            disabled={isEmptyArray(optionsOrder)}
          />
        </Col>
      </Row>

      <div className="container-btn">
        <Button
          className="bn-analysis"
          onClick={validateFiles}
          disabled={isNull(currentYear) || isNull(currentPeriod) || isNull(currentOrder)}
        >
          Iniciar el análisis de archivos
        </Button>
      </div>
      {/* <DropZone /> */}
    </div>
  );
};
PSLoadingFiles.displayName = 'PSLoadingFiles';

PSLoadingFiles.defaultProps = {
  files: [],
  loadingFiles: false,
};

PSLoadingFiles.propTypes = {
  files: PropTypes.array,
  loadingFiles: PropTypes.bool,
};
export default PSLoadingFiles;
