export const annualOptions = [{ label: '1 año', value: 1 }];

export const biannualOptions = [
  { label: '1er semestre', value: 1 },
  { label: '2do semestre', value: 2 },
];
export const quarterlyOptions = [
  { label: '1er trimestre', value: 1 },
  { label: '2do trimestre', value: 2 },
  { label: '3er trimestre', value: 3 },
  { label: '4to trimestre', value: 4 },
];
export const monthlyOptions = [
  { label: 'Enero', value: 1 },
  { label: 'Febrero', value: 2 },
  { label: 'Marzo', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Mayo', value: 5 },
  { label: 'Junio', value: 6 },
  { label: 'Julio', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Septiembre', value: 9 },
  { label: 'Octubre', value: 10 },
  { label: 'Noviembre', value: 11 },
  { label: 'Diciembre', value: 12 },
];
