import React from 'react';
import './index.less';
import PropTypes from 'prop-types';
import { Typography, Divider } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Title, Paragraph } = Typography;

const TitlePage = ({ title, note, secondTitle, onAction }) => {
  return (
    <>
      <div className="container-head-titlePage">
        <Title level={3} className="title-titlePage">
          {title}
        </Title>
        {secondTitle !== '' && (
          <div className="secondSection-titlePage">
            <CustomIcon type="spinner" className="icon-secondSection" onClick={onAction} />
            <Paragraph className="title-secondSection">{secondTitle}</Paragraph>
          </div>
        )}
      </div>
      <Divider className="divider-titlePage" />
      <Paragraph className="subtitle-home">{note}</Paragraph>
    </>
  );
};
TitlePage.displayName = 'TitlePage';

TitlePage.defaultProps = {
  title: 'Subir archivos',
  secondTitle: '',
  note: '*Peso máximo de archivos a subir es de 5mb',
  onAction: () => {},
};

TitlePage.propTypes = {
  title: PropTypes.string,
  secondTitle: PropTypes.string,
  note: PropTypes.string,
  onAction: PropTypes.func,
};
export default TitlePage;
