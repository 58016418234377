import { doGetWithParams } from '../../data/api/ApiService';
import { Error } from '../../data/entities/Error';

export default class DocRepository {
  static async getPresingUrl(type, fileName) {
    let data = [];
    let error = null;

    try {
      const response = await doGetWithParams(
        `${process.env.REACT_APP_BASE_URL_PRESING}/${type}`,
        null,
        {
          'file-name': fileName,
        }
      );
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = dataResponse;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async uploadFile(presingedUrl, data, type) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', type);

      const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: data,
        redirect: 'follow',
      };

      const response = await fetch(presingedUrl, requestOptions);
      await response.text();
      return [true, null];
    } catch (error) {
      const err = new Error({ code: '500', message: error });
      return [null, err];
    }
  }

  static async downloadFileUrl(type, id) {
    let data = [];
    let error = null;

    try {
      const response = await doGetWithParams(
        `${process.env.REACT_APP_BASE_URL_PRESING}/${type}/${id}`
      );
      const dataResponse = response.data;
      if (response.status === 200 && dataResponse) {
        data = dataResponse;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
