import React from 'react';
import './index.less';
import { Row, Col } from 'antd';

const HeadTable = ({ head, className }) => {
  return (
    <div>
      <Row className={`row-headTable ${className}`}>
        {head?.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Col key={`head-${index}`} span={item.width}>
              {item.title}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default HeadTable;
