/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Typography, Checkbox } from 'antd';

import { pathServer } from '../../utilities/Constant';
import { CustomIcon, Loading } from '../../components/index';

import './index.less';

const { Title, Paragraph } = Typography;

const LoginTemplate = ({ loading, formik, isError, setFormSent }) => {
  const { user = '', password = '' } = formik.values;
  const [activeInput, setActiveInput] = useState(false);
  const [lsCredentials, setLsCredentials] = useState({});

  useEffect(() => {
    const localStrg = localStorage.getItem('credentials');
    if (!localStrg) {
      setLsCredentials(JSON.parse(localStrg));
    }
  }, []);

  useEffect(() => {
    if (user) {
      setActiveInput(true);
    }
  }, [user]);

  const changeError = () => {
    setFormSent(false);
  };

  return (
    <div className="container-loginPage">
      <div className="firstSection-login">
        <form onSubmit={formik.handleSubmit}>
          <img src={`${pathServer.PATH_IMG}logo_login.png`} />
          <div className="container-title-login">
            <Title className="title-login platform">Plataforma </Title>
            <Title className="title-login sucavec">SUCAVE</Title>
          </div>
          <Paragraph className={!isError ? 'subtitle-login' : 'subtitle-login-error'}>
            Ingresa a tu cuenta
          </Paragraph>
          {isError && (
            <div className="container-alertMessage">
              <CustomIcon />
              <Paragraph className="text-alertMessage">
                Lo sentimos, la <span className="text-bold">clave/correo electrónico</span> que has
                ingresado <span className="text-bold">NO es correcta/o</span>. Pero no te preocupes,
                te ayudamos a recordarla haciendo click{' '}
                <span className="text-underline text-bold">aquí</span>
              </Paragraph>
            </div>
          )}
          <div className="input-login">
            <input
              type="text"
              {...formik.getFieldProps('user')}
              required
              value={formik.values.user}
              onChange={(e) => changeError(formik.handleChange(e))}
              className={
                // eslint-disable-next-line no-nested-ternary
                formik.errors.user ? 'input-warning' : activeInput && 'input-active'
              }
              placeholder=""
            />
            <span className="label-login">Correo electrónico</span>
          </div>
          <p className="warning">{formik.errors.user}</p>

          <div className="input-login">
            <input
              type="password"
              {...formik.getFieldProps('password')}
              className={formik.errors.password ? 'input-warning' : ''}
              value={password}
              onChange={(e) => changeError(formik.handleChange(e))}
              required
            />
            <span className="label-login">Clave</span>
          </div>
          <p className="warning">{formik.errors.password}</p>
          <div className="container-passwordLogin">
            <Checkbox
              className="f-size"
              defaultChecked={lsCredentials?.remember || false}
              {...formik.getFieldProps('remember')}
            >
              Recordar credenciales
            </Checkbox>
            {/* <span className={isError ? 'text-bold text-underline' : ' text-underline'}>
              ¿Olvidaste tus credenciales?
            </span> */}
          </div>
          <button className="bn-login" type="submit">
            Ingresar
          </button>
          {/* <Button className="bn-login bn-createAccount">Crear cuenta</Button> */}
        </form>
      </div>
      <div className="secondSection-login">
        <img src={`${pathServer.PATH_IMG}image_login.png`} />
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default LoginTemplate;
