import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from './Routes';
import '../../../resources/styles/base.less'; // or 'antd/dist/antd.less'
import LoginPage from '../../pages/Login/LoginPage';
import TrackingPage from '../../pages/Tracking/TrackingPage';
import withDashboard from '../../hocs/DashboardPage';
import FileUploadPage from '../../pages/FileUpload/FileUpload';
import IconDocumentation from '../../pages/IconDocumentation/IconDocumentation';

const RoutesPage = () => {
  const { errorLogin } = useSelector((state) => state.user);

  const PrivateRoute = ({ component, path, ...rest }) => {
    if (errorLogin === null) {
      return <Route component={component} path={path} {...rest} />;
    }
    return <Redirect to={`${Routes.LOGIN}`} {...rest} />;
  };
  return (
    <Router>
      <Switch>
        <Route exact path={Routes.MAIN} render={() => <Redirect to={Routes.LOGIN} />} />
        <Route exact path={Routes.LOGIN} component={LoginPage} />
        <PrivateRoute
          exact
          path={Routes.FILE_UPLOAD}
          component={withDashboard(FileUploadPage, '1')}
        />
        <PrivateRoute exact path={Routes.TRACKING} component={withDashboard(TrackingPage, '2')} />
        <PrivateRoute exact path={Routes.ICONS} component={IconDocumentation} />
      </Switch>
    </Router>
  );
};

export default RoutesPage;
