import React from 'react';
import { Space, Typography } from 'antd';

import { pathServer } from '../../utilities/Constant';

import './index.less';

const { Paragraph, Title } = Typography;

const UserInfo = () => {
  return (
    <div className="user-info-container">
      <Space className="container-image">
        <img width="150" src={`${pathServer.PATH_IMG}logo_oficial.jpeg`} />
      </Space>
      <div className="info-user">
        <Title level={5}>James Rodriguez</Title>
        <Paragraph className="subtitle">Gloria Ganaderos</Paragraph>
      </div>
    </div>
  );
};

UserInfo.propTypes = {};

export default UserInfo;
