import React from 'react';
import { Layout } from 'antd';
import './index.less';
import WMenu from '../Menu';

const { Sider } = Layout;

const WSidebar = ({ collapsed, history, selected }) => {
  return (
    <Sider
      width={188}
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      theme="light"
      align="center"
      collapsedWidth={0}
      trigger={null}
      className="sidebar-container"
    >
      <WMenu history={history} className="menu-item" selected={selected} />
    </Sider>
  );
};

WSidebar.propTypes = {};

export default WSidebar;
