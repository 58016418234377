import { formatDate, getNameUrl, statusTracking } from '../../app/utilities/Functions';

export class Tracking {
  constructor(props) {
    this.tracking = props.Tracking;
    this.timestampId = props.TimestampId;
    this.trackingEmail = props.Tracking?.filter((r) => r.Order === 2);
    this.tempName = props.Key?.split('/');
    this.period = props.Period;
    if (props.Size < 1000) {
      this.size = `${props.Size} kb`;
    } else {
      this.size = `${props.Size / 1000} mb`;
    }
    this.status = statusTracking(props.Tracking, props.Analyze?.validation);
    this.attachmentFile = props.Tracking?.some((e) => e?.AttachmentFileUrl?.length > 0);
    this.analyze = props.Analyze;
    this.analyzeValidation = props.Analyze?.validation;
    this.negative = props.Analyze?.validation?.negative || props.Analyze?.validation?.Negative;
    this.positive = props.Analyze?.validation?.positive || props.Analyze?.validation?.Positive;
    this.analyzeDate = props.Analyze?.date;
    this.creationDate = formatDate(props.CreationDate, 'L');
    this.url = props.url;
    this.id = props.Sort;
    this.error = props.Analyze?.validation?.Negative !== 0 || false;
    this.name = props.Key;
    this.fileName = getNameUrl(props.Key);
    this.analyzePeriod = props.Analyze?.period;
    this.analyzeUrl = props.Analyze?.url;
  }
}
