import { stringToHTML } from '../../app/utilities/Functions';

export class CurrentEmail {
  constructor(props) {
    this.id = props.id;
    this.toName = props.To?.Name;
    this.Body = props.Body.replace('(Coordinated Universal Time)', '');
    this.Date = props.Date;
    this.Subject = props.Subject;
    this.BodyDetail = props.BodyDetail;
    this.FROM = `${props.From?.Name}<${props.From?.Email}>`;
    this.TO = `${props.To?.Name}<${props.To?.Email}>`;
    this.CC = `${props.CC?.map(
      (e, index) => `${e.Email}${index === props.CC?.length - 1 ? '' : ''}`
    )}`;
    this.CCO = `${
      props.CCO?.length > 0
        ? props.CCO?.map((e, index) => `${e.Email}${index === props.CCO?.length - 1 ? '' : ''}`)
        : ''
    }`;
    this.Header = `${stringToHTML(props.Body?.split('<table')[0])}`;
    this.Footer = `${stringToHTML(props.Body?.split('</table>')[1])}`;
    this.Signing = getSigning(props.Body);
    this.Table = formatTableBody(props.Body);
    this.TableBT = formatTableBody(props.BodyTemplate);
  }
}
const formatTableBody = (body) => {
  const inicio = body?.indexOf('<table', 0);
  const fin = body?.indexOf('</table>', inicio);
  return body?.slice(inicio, fin + 8).replace('(Coordinated Universal Time)', '');
};
const getSigning = (body) => {
  const inicio = body?.indexOf('<img', 0);
  const fin = body?.indexOf('>', inicio);
  return inicio && fin && body?.slice(inicio, fin + 1);
};
