import moment from 'moment'; // https://momentjs.com/
import times from 'lodash/times';
import { Icons } from './Constant';
import DocumentServices from '../../domain/usecases/DocumentServices';

export function lowerExtension(file) {
  const { name, lastModified, type } = file;
  const parts = name.split('.');
  const extension = parts.pop().toLowerCase();
  const newFileName = `${parts.join('.')}.${extension}`;
  const newFile = new File([file], newFileName, { lastModified, type });
  return newFile;
}

export function getIcon(type) {
  if (Icons[type] !== undefined) {
    return Icons[type].url;
  }
  return Icons.default.url;
}
export function getNameUrl(fileName) {
  const itemFile = fileName?.split('/');
  const lengthName = itemFile.length;
  return itemFile && itemFile[lengthName - 1];
}
export function formatDate(date, format = 'L') {
  return moment(date || new Date()).format(format);
}
export function getCurrentYear() {
  const date = new Date();
  return date.getFullYear();
}

export function getListLastYears(numberYears = 1) {
  const date = new Date();

  const currentYear = date.getFullYear();
  return times(numberYears, (i) => {
    return { label: currentYear - i, value: currentYear - i };
  });
}
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
export function isEmptyArray(arr) {
  return arr?.length === 0;
}
export function dateGMT(time, dateFormat = 'DD/MM/YYYY') {
  if (time) {
    return moment(time).utc().format(dateFormat);
  }
  return null;
}

export function formatInputs(type, value) {
  let formatFT = [];
  let newFormat = '';
  switch (type) {
    case 'FROM':
    case 'TO':
      return { Name: '', Email: value };
    case 'CC':
    case 'CCO':
      if (value) {
        formatFT = value?.split(',')?.map((e) => {
          return { Name: '', Email: e.trim() };
        });
      }
      return formatFT;

    case 'Header':
    case 'Footer':
      if (value) {
        newFormat = `<p>${value}</p>`;
      }
      return newFormat;
    default:
      return value;
  }
}
export function stringToHTML(text) {
  return text
    .replace(/<br \/>/g, '\n')
    .replace(/&(lt|gt);/g, (strMatch, p1) => (p1 === 'lt' ? '<' : '>'))
    .replace(/<\/?[^>]+(>|$)/g, '');
}

export function orderArray(items, campoOrder) {
  return items.sort((a, b) => {
    if (a[campoOrder] > b[campoOrder]) return 1;
    if (a[campoOrder] < b[campoOrder]) return -1;
    return 0;
  });
}

export function statusTracking(tracking, validation) {
  const trackingAction = tracking?.filter((e) => e.Order === 3 || e.Order === 4);
  const allResponsible = tracking?.filter((r) => r.Order === 2).map((e) => e.Responsible);
  if (trackingAction === undefined || isEmptyArray(trackingAction)) {
    if (validation?.Negative === 0 && validation?.Positive > 0) return 'Finalizado';
    return 'En Seguimiento';
  }
  const indexLast = [];
  allResponsible.forEach((r) => {
    const elements = trackingAction.filter((e) => e.Responsible === r);
    if (elements?.length > 0) indexLast.push(elements[elements.length - 1].Order);
  });
  const completedStatus =
    isEmptyArray(indexLast) || indexLast.length !== allResponsible.length
      ? false
      : indexLast.every((e) => e === 4);
  return completedStatus ? 'Finalizado' : 'En Seguimiento';
}
export function formatUpdateEmail(email) {
  return {
    CC: formatInputs('CC', email.CC),
    CCO: formatInputs('CC', email.CCO),
    Subject: email.Subject,
    BodyDetail: email.BodyDetail,
    BodyTemplate: `<p>${email.Header.replace(/\n/g, '<br />')}</p>${
      email.TableBT
    }<p>${email.Footer.replace(/\n/g, '<br />')}</p>${email.Signing}`,
  };
}
export async function generateFileUrl(type, file) {
  try {
    let url = '';
    const [presingUrlData, errorPresingUrl] = await DocumentServices.getPresingUrl(type, file.name);

    if (presingUrlData && errorPresingUrl === null) {
      const { id, presingedUrl } = presingUrlData[0];
      const [uploadFileData, errorUploadFile] = await DocumentServices.uploadFile(
        presingedUrl,
        file,
        file.type
      );
      if (uploadFileData && errorUploadFile === null) {
        const [data, error] = await DocumentServices.downloadFile(type, id);
        if (data && error === null) {
          const { presingedUrl: Url } = data[0];
          url = Url;
        }
      }
    }
    return { url, ...presingUrlData[0] };
  } catch (e) {
    console.log(e);
    return '';
  }
}
export async function downloadFileUrl(type, id) {
  const [data] = await DocumentServices.downloadFile(type, id);
  if (data) window.open(data[0].presingedUrl);
}
