/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { loginUser, logout } from '../../redux/ducks/user-loginDuck';
import { Routes } from '../../configuration/routes/Routes';

import LoginTemplate from '../../templates/Login/LoginTemplate';
import LoadingLogin from '../../templates/LoadingLogin/LoadingLogin';

const LoginPage = () => {
  const { errorLogin, loading } = useSelector((state) => state.user);
  const history = useHistory();
  const [loadingView, setLoadingView] = useState(true);
  const [formSent, setFormSent] = useState(false);
  const [rememberCredentials, setRememberCredentials] = useState(false);
  const [Credentials, setCredentials] = useState('');
  const [lsCredentials, setLsCredentials] = useState({});
  const dispatch = useDispatch();

  const login = async (user, password) => {
    dispatch(loginUser(user, password));
  };

  useEffect(() => {
    const localStrg = localStorage.getItem('credentials');
    if (!localStrg) {
      const credentialsUser = JSON.parse(localStrg);
      setLsCredentials(credentialsUser);
    }
  }, []);

  useEffect(() => {
    if (!formSent && !isEmpty(errorLogin)) dispatch(logout());
  }, [formSent]);

  const formik = useFormik({
    initialValues: {
      user: lsCredentials?.user || '',
      password: lsCredentials?.password || '',
      remember: lsCredentials?.remember || false,
    },
    validationSchema: Yup.object().shape({
      user: Yup.string().required('Usuario es requerido'),
      password: Yup.string()
        .min(8, 'Necesita mínimo 6 caracteres')
        .required('La contraseña es requerida'),
    }),
    onSubmit(form) {
      setFormSent(true);
      const { user, password, remember } = form;
      setRememberCredentials(remember);
      setCredentials(form);
      login(user, password);
    },
  });

  setTimeout(() => {
    setLoadingView(false);
  }, 1000);

  useEffect(() => {
    if (errorLogin !== undefined) {
      if (errorLogin === null) {
        if (rememberCredentials === true) {
          localStorage.setItem('credentials', JSON.stringify(Credentials));
        } else {
          localStorage.removeItem('credentials');
        }
        history.push(Routes.FILE_UPLOAD);
      }
    }
  }, [errorLogin]);

  return (
    <div>
      {loadingView ? (
        <LoadingLogin />
      ) : (
        <LoginTemplate
          loading={loading}
          formik={formik}
          isError={formSent && errorLogin !== undefined}
          setFormSent={setFormSent}
        />
      )}
    </div>
  );
};

export default LoginPage;
