import DocRepository from '../repositories/DocRepository';

export default class DocumentServices {
  static async getPresingUrl(type, fileName) {
    const [data, error] = await DocRepository.getPresingUrl(type, fileName);
    return [data, error];
  }

  static async uploadFile(presignedUrl, file, type) {
    const [data, error] = await DocRepository.uploadFile(presignedUrl, file, type);
    return [data, error];
  }

  static async downloadFile(type, id) {
    const [data, error] = await DocRepository.downloadFileUrl(type, id);
    return [data, error];
  }
}
