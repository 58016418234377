import { orderArray } from '../../app/utilities/Functions';
import { doGet, doDelete, doPost, doGetWithParams, doPut } from '../../data/api/ApiService';

import { Error } from '../../data/entities/Error';
import { Tracking } from '../../data/entities/Tracking';

export default class FileRepository {
  static async getAll() {
    let data = [];
    let error = null;
    try {
      const response = await doGet(`${process.env.REACT_APP_FILES}`);
      const dataResponse = response.data;
      if (response.status === 200 && dataResponse) {
        data = orderArray(
          dataResponse.map((item) => new Tracking(item)),
          'timestampId'
        ).reverse();
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async getAllIdsByDate(timeStamp) {
    let data = [];
    let error = null;

    try {
      const response = await doGet(`${process.env.REACT_APP_FILES}?limit=1&start=${timeStamp}`);
      const dataResponse = response.data;

      if (response.status === 200 && dataResponse) {
        data = dataResponse.map((item) => item.Sort);
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async deleteFile(id) {
    let data = null;
    let error = null;
    try {
      const response = await doDelete(`${process.env.REACT_APP_FILES}/${id}`);

      if (response.status === 200 && response.data) {
        data = true;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async analizeFile(ids) {
    let data = [];
    let error = null;

    try {
      const response = await doPost(`${process.env.REACT_APP_FILES}`, ids);
      const dataResponse = response.data;

      if (response.status === 200) {
        data = dataResponse;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async updatePeriod(obj) {
    const { id, ...payload } = obj;
    let data = [];
    let error = null;

    try {
      const response = await doPut(`${process.env.REACT_APP_BASE}/file/${id}`, payload);
      const dataResponse = response.data;

      if (response.status === 200) {
        data = dataResponse;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async getResultAnalized(id) {
    let dataResult = [];
    let error = null;
    const headerParams = {
      entity: 'FILE',
    };
    try {
      const { status, data } = await doGetWithParams(
        `${process.env.REACT_APP_FILES}?limit=1&start=${id}`,
        null,
        headerParams
      );

      if (status === 200 && data) {
        dataResult = data.map((d) => new Tracking(d));
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [dataResult, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [dataResult, error];
    }
  }

  static async createTracking(tracking, idTracking) {
    let data = [];
    let error = null;

    try {
      const response = await doPost(
        `${process.env.REACT_APP_CREATE_TRACKING}/${idTracking}`,
        tracking
      );
      const dataResponse = response.data;
      if (response.status === 200) {
        data = dataResponse;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
