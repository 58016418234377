// Constants
const EXAMPLE_SUCCESS = 'EXAMPLE_SUCCESS';

// Reducer

export default function exampleReducer(state = {}, action) {
  switch (action.type) {
    case EXAMPLE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

// Actions

// eslint-disable-next-line no-unused-vars
export const loadExample = () => async (dispatch, getState) => {
  // todo implement all thinks
  const persona = {
    nombre: 'juna',
    edad: 20,
    state: true,
  };

  dispatch({
    type: EXAMPLE_SUCCESS,
    payload: persona,
  });
};
