import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'antd';
import './index.less';
import CustomIcon from '../CustomIcon/CustomIcon';

export const MAX_SIZE_UPLOAD_IMG = 248000;

export default function DropZone({ onSelectFile, smallDropzone }) {
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState(null);
  const onDrop = (e, file) => {
    if (file.length > 0) {
      const { size } = file[0].file;
      if (size < MAX_SIZE_UPLOAD_IMG) {
        const imageUrl = URL.createObjectURL(file[0].file);
        // setFileLoaded(file[0].file);
        onSelectFile(file[0].file, imageUrl);
        setImage(imageUrl);
      } else {
        // eslint-disable-next-line no-alert
        alert('El peso máximo del archivo es 248kb');
      }
    }
  };
  return (
    <div>
      <Dropzone
        maxSize={2048}
        // accept=".jpeg,.jpg,.png,.xls"
        multiple={false}
        onDragEnter={(e) => {
          console.log('valores', e);
        }}
        onDrop={(e, file) => onDrop(e, file)}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              className={`container-dropzone ${smallDropzone ? 'smallDropzone' : ''}`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <div>
                <CustomIcon type="upload" />
              </div>

              <div className="text-upload">Suelta los archivos aquí o haz clic en ...</div>
              <Button className="bn-upload">Agregar archivo</Button>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

DropZone.displayName = 'DropZone';
