import EmailRepository from '../repositories/EmailRepository';

export default class EmailServices {
  static async sendEmail(id, fromName) {
    const [data, error] = await EmailRepository.sendEmail(id, fromName);
    return [data, error];
  }

  static async updateEmail(id, fromName, email) {
    const [data, error] = await EmailRepository.updateEmail(id, fromName, email);
    return [data, error];
  }
}
