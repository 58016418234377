import { doPut } from '../../data/api/ApiService';
import { Error } from '../../data/entities/Error';

import { Tracking } from '../../data/entities/Tracking';

export default class EmailRepository {
  static async sendEmail(id, fromName) {
    let data = [];
    let error = null;

    try {
      const response = await doPut(
        `${process.env.REACT_APP_UPDATE_EMAIL}/send/${id}?fromName=${fromName}`
      );
      const dataResponse = response.data;
      if (response.status === 200 && dataResponse) {
        data = dataResponse;
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }

  static async updateEmail(id, fromName, email) {
    let data = [];
    let error = null;

    try {
      const response = await doPut(
        `${process.env.REACT_APP_UPDATE_EMAIL}/${id}?fromName=${fromName}`,
        email
      );

      const dataResponse = response.data;
      if (response.status === 200 && dataResponse) {
        data = new Tracking(dataResponse);
      } else {
        error = new Error({ code: '400', message: 'Not data' });
      }
      return [data, error];
    } catch (e) {
      error = new Error({ code: '500', message: e.message });
      return [data, error];
    }
  }
}
