import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { getIcon } from '../../utilities/Functions';

const CustomIcon = React.forwardRef(({ children, className, type, onClick, ...props }, ref) => (
  <ReactSVG ref={ref} src={getIcon(type)} onClick={onClick} className={className} {...props}>
    {children}
  </ReactSVG>
));

CustomIcon.displayName = 'CustomIcon';

CustomIcon.defaultProps = {
  onClick: () => {},
  type: 'default',
  className: null,
};

CustomIcon.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CustomIcon;
