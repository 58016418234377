/* eslint-disable no-nested-ternary */
import React from 'react';
import './index.less';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import CustomIcon from '../CustomIcon/CustomIcon';

const { Paragraph } = Typography;

const UploadNotification = ({ type, text, className, onClose }) => {
  return (
    <div className={`container-uploadNotification ${className} ${type}`}>
      {type === 'type-one' ? (
        <CustomIcon type="spinner" className="icon-spinner rotate" />
      ) : type === 'type-three' ? (
        <CustomIcon type="alert" className="icon-uploadNotification" />
      ) : (
        <CustomIcon type="checkNotification" className="icon-uploadNotification" />
      )}
      <Paragraph className="text-uploadNotification">{text}</Paragraph>
      <div onClick={onClose}>
        <Paragraph className="ic-close">×</Paragraph>
      </div>
    </div>
  );
};
UploadNotification.displayName = 'UploadNotification';

UploadNotification.defaultProps = {
  type: 'one',
  text: 'Un momento por favor… Los archivos se están subiendo',
};

UploadNotification.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};
export default UploadNotification;
