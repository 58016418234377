import React from 'react';
import { Menu } from 'antd';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

import { pathServer } from '../../utilities/Constant';
import { Routes } from '../../configuration/routes/Routes';

import './index.less';

const WMenu = ({ className, history, selected }) => {
  return (
    <>
      <p className="title-menu">Casos</p>
      <Menu defaultSelectedKeys={[selected]} mode="inline" theme="light">
        <Menu.Item
          key="1"
          className={className}
          icon={
            <ReactSVG
              src={`${pathServer.PATH_ICONS}ic_uploadFiles.svg`}
              style={{ paddingTop: '10px' }}
            />
          }
          onClick={() => history.push(Routes.FILE_UPLOAD)}
        >
          Carga de archivos
        </Menu.Item>
        <Menu.Item
          key="2"
          className={className}
          icon={
            <ReactSVG
              src={`${pathServer.PATH_ICONS}ic_tracking.svg`}
              style={{ paddingTop: '10px' }}
            />
          }
          onClick={() => history.push(Routes.TRACKING)}
        >
          Seguimiento
        </Menu.Item>
        {/* <Menu.Item
          key="3"
          className={className}
          icon={
            <ReactSVG
              src={`${pathServer.PATH_ICONS}ic_reports.svg`}
              style={{ paddingTop: '10px' }}
            />
          }
        >
          Reportes
        </Menu.Item> 
        <Menu.Item
          key="4"
          className={className}
          icon={
            <ReactSVG
              src={`${pathServer.PATH_ICONS}ic_dashboard.svg`}
              style={{ paddingTop: '10px' }}
            />
          }
        >
          Dashboard
        </Menu.Item> */}
      </Menu>
    </>
  );
};
WMenu.defaultProps = {
  className: '',
  selected: '',
};

WMenu.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
};

export default WMenu;
