import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'antd';

import { TitlePage, RowAnalysisResult } from '../../../components/index';

import './index.less';

const PSValidationResults = ({ files, options, showSendEmailModal, onUpdateResultAnalized }) => {
  const currentDate = new Date().toLocaleString('es-PE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });

  return (
    <div className="content-validationResults">
      <TitlePage
        title="Resultados de Análisis de Archivos"
        note=" Archivos que tienen el ícono de E-mail deben seleccionarlos para enviar la respectiva comunicación a los responsables"
      />
      <Row justify="space-between">
        <Col span="8">
          <span className="date">Carga del {currentDate} </span>
        </Col>
        <Col span="4">
          <Button className="btn-update" onClick={onUpdateResultAnalized}>
            Actualizar
          </Button>
        </Col>
      </Row>
      <RowAnalysisResult files={files} options={options} showSendEmailModal={showSendEmailModal} />
    </div>
  );
};
PSValidationResults.displayName = 'PSValidationResults';

PSValidationResults.defaultProps = {
  files: [],
};

PSValidationResults.propTypes = {
  files: PropTypes.array,
};
export default PSValidationResults;
