/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import EmailRepository from '../../../domain/repositories/EmailRepository';
import EmailServices from '../../../domain/usecases/EmailServices';
import FileServices from '../../../domain/usecases/FileServices';
import TrackingTemplate from '../../templates/Tracking/TrackingTemplate';
import { Tracking } from '../../../data/entities/Tracking';
import {
  formatInputs,
  formatUpdateEmail,
  generateFileUrl,
  isEmptyArray,
} from '../../utilities/Functions';

const TrackingPage = () => {
  const initialEmail = {
    CC: [
      {
        Name: ' ',
        Email: ' ',
      },
    ],
    CCO: [
      {
        Name: ' ',
        Email: ' ',
      },
    ],
    Subject: ' ',
    BodyTemplate: '',
  };
  const initialTracking = {
    Name: 'En Proceso',
    Order: 3, // sumar el orden
    Responsible: null,
    Action: null,
    Channel: 'Plataforma',
    AttachmentFile: [],
    Comments: '',
    Subject: '',
    Clear: true,
    Save: false,
  };
  const errorDefault = {
    value: false,
    title: '',
    subtitle: '',
    nameButton: '',
    functionError: () => {},
  };
  const [loading, setLoading] = useState(true);
  const [trackingModal, setTrackingModal] = useState(false);
  const [modalNewAction, setModalNewAction] = useState(false);
  const [modalSaveAction, setModalSaveAction] = useState(false);
  const [trackingFiles, setTrackingFiles] = useState([]);
  const [sendEmail, setSendEmail] = useState({ ...initialEmail });
  const [tracking, setTracking] = useState({ ...initialTracking });
  const [email, setEmail] = useState({ ...initialEmail });
  const [dateFilter, setDateFilter] = useState(null);
  const [currentDatatranking, setCurrentDatatranking] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [loadingSaveAction, setLoadingSaveAction] = useState(false);
  const [onListEmailModal, setListEmailModal] = useState(false);
  const [onViewEmailModal, setViewEmailModal] = useState(false);
  const [trackingFileFilter, setTrackingFileFilter] = useState([]);
  const [onEditEmailModal, setEditEmailModal] = useState(false);
  const [notificationType, setNotificationType] = useState(false);
  const [onSendEmailModal, setSendEmailModal] = useState(false);
  const [onErrorValidationModal, setOnErrorValidationModal] = useState(errorDefault);
  const [onConfirmUpdateEmailModal, setConfirmUpdateEmailModal] = useState(false);
  const [onUpdateEmailModal, setUpdateEmailModal] = useState(false);
  const [onDownloadModal, setDownloadModal] = useState(false);
  const [loadingUpdateEmail, setLoadingUpdateEmail] = useState(false);

  const headTable = [
    {
      title: 'Registros',
      width: 2,
    },
    {
      title: 'Nombre de Archivo',
      width: 5,
    },
    {
      title: 'Fecha subida',
      width: 3,
    },
    {
      title: 'Peso',
      width: 2,
    },
    {
      title: 'Periodo',
      width: 2,
    },
    {
      title: 'Estatus',
      width: 2,
    },
    {
      title: 'Validación',
      width: 3,
    },
    {
      title: 'Acciones',
      width: 5,
    },
  ];
  const showEditEmailModal = (value) => {
    setEditEmailModal(value);
  };
  const showErrorValidationModal = (value) => {
    setOnErrorValidationModal({ ...errorDefault, ...value });
  };

  const onCreateTracking = async (idTracking) => {
    const [data, error] = await FileServices.createTracking(tracking, idTracking);
    setLoadingSaveAction(false);
    setModalSaveAction(false);
    setModalNewAction(false);
    if (error === null && data) {
      const newItem = new Tracking(data);
      const index = trackingFiles.findIndex((item) => item.id === newItem.id);
      trackingFiles[index] = newItem;
      setItemSelected(newItem);
      setTrackingFiles([...trackingFiles]);
    } else {
      showErrorValidationModal({
        value: true,
        title: 'Proceso con Errores',
        subtitle: 'Se presentaron inconvenientes',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
        },
      });
    }
  };

  const onUploadFile = async () => {
    if (currentDatatranking) {
      const { files, idTracking } = currentDatatranking;

      if (files.length === 0) {
        onCreateTracking(idTracking);
      } else {
        const idFiles = files.map((e) => e.id);
        setTracking({ ...tracking, AttachmentFile: idFiles, Save: true });
      }
    }
  };

  const onChangeFilter = (date) => {
    setDateFilter(date);
  };
  const onChangeSelected = (data) => {
    setItemSelected(data);
  };
  const showTrackingModal = (value) => {
    setTrackingModal(value);
  };
  const onShowNewAction = (value) => {
    setModalNewAction(value);
    setTracking({ ...initialTracking });
  };
  const onChangeLoadingSaveAction = (value) => {
    setLoadingSaveAction(value);
  };
  const showListEmailModal = (value) => {
    setListEmailModal(value);
  };
  const showViewEmailModal = (value) => {
    setViewEmailModal(value);
  };
  const showUpdateEmailModal = (value) => {
    setUpdateEmailModal(value);
  };
  const showDownloadModal = (value) => {
    setDownloadModal(value);
  };
  const showConfirmUpdateEmailModal = (value) => {
    setConfirmUpdateEmailModal(value);
  };

  const onShowSaveAction = (value, files, idTracking, currentTracking) => {
    if (value) {
      setCurrentDatatranking({ files, idTracking });
      setTracking({ ...currentTracking, Clear: false });
    }
    setModalSaveAction(value);
  };

  const onConfirmModal = (value) => {
    if (value) {
      onUploadFile();
    } else {
      setModalSaveAction(false);
    }
  };

  const onGetTracking = async () => {
    setLoading(true);
    const [data, error] = await FileServices.getAll();
    if (error === null && data) {
      const newData = data.filter((item) => item.analyze !== undefined);
      setTrackingFiles([...newData]);
    }
    setLoading(false);
  };

  const onSendEmail = async (id, fromName) => {
    setNotificationType('sendingEmail');
    setLoading(true);
    const [data, error] = await EmailServices.sendEmail(id, fromName);
    if (error === null && data) {
      setSendEmailModal(true);
      const index = itemSelected.trackingEmail.findIndex((e) => e.Emails.To.Name === fromName);
      itemSelected.trackingEmail[index].Emails = data;
      setItemSelected({ ...itemSelected });

      const indexGeneral = trackingFiles.findIndex((item) => item.id === id);
      trackingFiles[indexGeneral].trackingEmail = itemSelected.trackingEmail;
      setTrackingFiles(trackingFiles);
    } else {
      showErrorValidationModal({
        value: true,
        title: 'Proceso de Envío de correo con Errores',
        subtitle: 'Se presentaron inconvenientes al enviar el correo',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
        },
      });
    }
    setNotificationType(false);
    setLoading(false);
  };

  const onUpdateEmail = async (currentEmail) => {
    setLoadingUpdateEmail(true);
    const newFormat = formatUpdateEmail(currentEmail);
    const [data, error] = await EmailServices.updateEmail(
      currentEmail.id,
      currentEmail.toName,
      newFormat
    );

    if (error === null && data) {
      showUpdateEmailModal(true);
      showConfirmUpdateEmailModal(false);

      setItemSelected(data);
      const index = trackingFiles.findIndex((item) => item.id === data.id);
      trackingFiles[index] = data;
      setTrackingFiles([...trackingFiles]);
    } else {
      showEditEmailModal(false);
      showConfirmUpdateEmailModal(false);
      showErrorValidationModal({
        value: true,
        title: 'Proceso de Actualización con Errores',
        subtitle: 'Se encontraron problemas al hacer la actualización del correo',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
        },
      });
    }
    setLoadingUpdateEmail(false);
  };

  useEffect(() => {
    onGetTracking();
  }, []);
  useEffect(() => {
    const filter =
      dateFilter !== null && dateFilter !== ''
        ? trackingFiles.filter((item) => item.creationDate === dateFilter)
        : trackingFiles;
    setTrackingFileFilter([...filter]);
  }, [dateFilter, trackingFiles]);

  useEffect(() => {
    if (tracking && tracking.Save && !isEmptyArray(tracking.AttachmentFile)) {
      const { idTracking } = currentDatatranking;
      onCreateTracking(idTracking);
    }
  }, [tracking]);

  return (
    <TrackingTemplate
      loading={loading}
      head={headTable}
      showTrackingModal={showTrackingModal}
      trackingModal={trackingModal}
      onShowNewAction={onShowNewAction}
      modalNewAction={modalNewAction}
      onShowSaveAction={onShowSaveAction}
      onConfirmModal={onConfirmModal}
      modalSaveAction={modalSaveAction}
      trackingFileFilter={trackingFileFilter}
      onChangeFilter={onChangeFilter}
      onGetTracking={onGetTracking}
      tracking={tracking}
      itemSelected={itemSelected}
      onChangeSelected={onChangeSelected}
      loadingSaveAction={loadingSaveAction}
      onChangeLoadingSaveAction={onChangeLoadingSaveAction}
      onListEmailModal={onListEmailModal}
      showListEmailModal={showListEmailModal}
      onViewEmailModal={onViewEmailModal}
      showViewEmailModal={showViewEmailModal}
      onEditEmailModal={onEditEmailModal}
      showEditEmailModal={showEditEmailModal}
      onSendEmail={onSendEmail}
      onUpdateEmail={onUpdateEmail}
      showNotificationType={(value) => setNotificationType(value)}
      onNotificationType={notificationType}
      onSendEmailModal={onSendEmailModal}
      showSendEmailModal={(value) => setSendEmailModal(value)}
      onErrorValidationModal={onErrorValidationModal}
      showErrorValidationModal={showErrorValidationModal}
      onConfirmUpdateEmailModal={onConfirmUpdateEmailModal}
      showConfirmUpdateEmailModal={showConfirmUpdateEmailModal}
      onUpdateEmailModal={onUpdateEmailModal}
      showUpdateEmailModal={showUpdateEmailModal}
      loadingUpdateEmail={loadingUpdateEmail}
      onDownloadModal={onDownloadModal}
      showDownloadModal={showDownloadModal}
    />
  );
};
export default TrackingPage;
