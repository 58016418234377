import React from 'react';
import './index.less';
import { Typography, Row, Col } from 'antd';
import Proptypes from 'prop-types';
import CustomIcon from '../CustomIcon/CustomIcon';
import HeadTable from '../HeadTable/HeadTable';

const { Title } = Typography;
const SentCommunModal = ({ files, caseName, onCancel }) => {
  const headSentCommun = [
    {
      title: 'Fecha de envío',
      width: 4,
    },
    {
      title: 'De:',
      width: 4,
    },
    {
      title: 'Para:',
      width: 4,
    },
    {
      title: 'Con copia a:',
      width: 4,
    },
    {
      title: 'Asunto',
      width: 5,
    },
    {
      title: 'Acciones',
      width: 3,
    },
  ];
  // todo mailTracking
  return (
    <div>
      <CustomIcon className="cancel-SentCommun" type="cancel" onClick={() => onCancel()} />
      <div className="container-SentCommun">
        <Title className="title-SentCommun">
          Comunicaciones enviadas para el caso {caseName}:{files.fileName}
        </Title>
        <HeadTable head={headSentCommun} />
        <Row key={files.date} className="row-contentSentCommun">
          <Col span={4} className="column-contentSentCommun">
            13/02/2022 05:24 p.m
          </Col>
          <Col span={4} className="column-contentSentCommun">
            José Zorrilla - Legal
          </Col>
          <Col span={4} className="column-contentSentCommun">
            Raúl Rodriguez - Finanzas
          </Col>
          <Col span={4} className="column-contentSentCommun">
            16 destinatarios
          </Col>
          <Col span={5} className="column-contentSentCommun">
            {files.fileName}
          </Col>
          <Col span={3} className="column-contentSentCommun">
            <div className="container-actionsSentCommun">
              <CustomIcon className="icon-actionsSentCommun" type="trash" />
              <CustomIcon className="icon-actionsSentCommun" type="trash" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

SentCommunModal.displayName = 'NewActionModal';

SentCommunModal.defaultProps = {
  files: [],
  caseName: 'Abr-004',
  onCancel: () => {},
};

SentCommunModal.propTypes = {
  files: Proptypes.array,
  caseName: Proptypes.string,
  onCancel: Proptypes.func,
};
export default SentCommunModal;
