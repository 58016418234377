import FileRepository from '../repositories/FileRepository';

export default class FileServices {
  static async getAll() {
    const [data, error] = await FileRepository.getAll();
    return [data, error];
  }

  static async getAllIdsByDate(timeStamp) {
    const [data, error] = await FileRepository.getAllIdsByDate(timeStamp);
    return [data, error];
  }

  static async deleteFile(id) {
    const [data, error] = await FileRepository.deleteFile(id);
    return [data, error];
  }

  static async analizeFile(ids) {
    const [data, error] = await FileRepository.analizeFile(ids);
    return [data, error];
  }

  static async getResultAnalized(id) {
    const [data, error] = await FileRepository.getResultAnalized(id);
    return [data, error];
  }

  static async createTracking(tracking, idTracking) {
    const [data, error] = await FileRepository.createTracking(tracking, idTracking);
    return [data, error];
  }

  static async updatePeriod(obj) {
    const [status, error] = await FileRepository.updatePeriod(obj);
    return [status, error];
  }
}
