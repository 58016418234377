import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Layout, Typography, Space, Modal } from 'antd';
import { ReactSVG } from 'react-svg';

import { Routes } from '../../configuration/routes/Routes';
import { logout } from '../../redux/ducks/user-loginDuck';

import WSideBar from '../../components/Sidebar';
import WDrawer from '../../components/Drawer/Drawer';
import ResultModal from '../../components/ResultModal/ResultModal';

import { pathServer, FIELD_NEXTTIME_LOGIN } from '../../utilities/Constant';

import './index.less';

// const SECONDS_RELOAD = 3600000;
const SESSION_TIME = 60;
const linkLogo = `${pathServer.PATH_IMG}logo_oficial.jpeg`;
const { Header, Content } = Layout;
const { Text } = Typography;

const withDashboard = (Component, itemSelected) => (props) => {
  const { fullName } = useSelector((state) => state.user);
  const [timer, setTimer] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  const onLogout = () => {
    const lsCredentials = localStorage.getItem('credentials');
    const credentialsUser = JSON.parse(lsCredentials);

    history.push(Routes.LOGIN);
    dispatch(logout(credentialsUser));
  };

  const redirectToLogin = () => {
    const lsCredentials = localStorage.getItem('credentials');
    const credentialsUser = JSON.parse(lsCredentials);

    dispatch(logout(credentialsUser));

    // console.log("credentialsUser", credentialsUser)

    // localStorage.removeItem(FIELD_NEXTTIME_LOGIN);
    // localStorage.clear();

    // credentialsUser !== null && localStorage.setItem('credentials', JSON.stringify(credentialsUser));

    window.location.replace(Routes.LOGIN);
  };

  const loadModal = () => {
    const actualDate = new Date();
    const loginDate =
      localStorage.getItem(FIELD_NEXTTIME_LOGIN) || moment().startOf('day').fromNow();
    if (loginDate) {
      const duration = moment.duration(moment(actualDate).diff(loginDate)).asMinutes();
      if (duration >= SESSION_TIME) {
        setShowSessionExpired(true);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (timer < SESSION_TIME) {
        loadModal();
        setTimer(timer + 1);
      }
    }, 180000);
  }, [timer]);

  useEffect(() => {
    if (!showSessionExpired) {
      loadModal();
    }
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }} theme="light" className="container-main">
      <WDrawer collapsed={collapsed} onChange={onCollapse} />
      <Header className="site-layout-background header" style={{ background: 'white' }}>
        <Space className="container-image">
          <img width="150" src={linkLogo} />
        </Space>
        <Space className="title-header">
          <div>
            <Text>Plataforma </Text>
            <Text className="font-bold"> SUCAVE</Text>
          </div>
          <div className="userInfo">
            <div>
              <Text className="font-bold d-block">{fullName}</Text>
              <Text className="d-block userPosition">Asesor Legal</Text>
            </div>
            {/* <Divider type="vertical" />
            <ReactSVG className="icon" src={`${pathServer.PATH_ICONS}avatar.svg`} /> */}
            <ReactSVG
              className="icon"
              src={`${pathServer.PATH_ICONS}ic_logout.svg`}
              onClick={() => {
                onLogout();
              }}
            />
          </div>
        </Space>
      </Header>
      <Layout>
        <WSideBar collapsed={collapsed} history={history} selected={itemSelected} />
        <Content style={{ margin: '0 16px' }}>
          <div style={{ padding: 24, minHeight: 360, background: 'white' }}>
            <Component {...props} />
          </div>
        </Content>
      </Layout>
      <Modal visible={showSessionExpired} className="hide-close-modal" footer={null}>
        <ResultModal
          icon="alert"
          title="Tu sesión ha expirado"
          message="Vuelve a iniciar sesión"
          subtitle="Vuelve a iniciar sesión"
          button="Acceptar"
          buttonActionFirst={redirectToLogin}
        />
      </Modal>
    </Layout>
  );
};

withDashboard.propTypes = {};

export default withDashboard;
