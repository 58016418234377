import React from 'react';
import { Divider, Tooltip, Table } from 'antd';
import Proptypes from 'prop-types';

import { pathServer } from '../../utilities/Constant';
import { isEmptyArray } from '../../utilities/Functions';

import CustomIcon from '../CustomIcon/CustomIcon';
import Select from '../Select/Select';

import './index.less';

const RowAnalysisResult = ({ files, options, showSendEmailModal }) => {
  return (
    <div>
      {files?.map(
        ({ fileName, positive, negative, analyzeUrl, period, trackingEmail, analyzeDate, id }) => {
          const columns = [
            {
              title: 'Archivo',
              dataIndex: 'negative',
              key: 'negative',
              width: 40,
              render: () => (
                <Tooltip placement="bottom" title={`${trackingEmail ? 'Descargar análisis' : ''}`}>
                  {negative === 0 ? (
                    <img src={`${pathServer.PATH_IMG}ic_excel_success.png`} />
                  ) : (
                    <a href={analyzeUrl} target="_blank" rel="noreferrer">
                      <img src={`${pathServer.PATH_IMG}ic_excel.png`} />
                    </a>
                  )}
                </Tooltip>
              ),
            },
            {
              title: 'Nombre de Archivo',
              dataIndex: 'fileName',
              key: 'fileName',
            },
            {
              title: 'Periodo',
              dataIndex: 'period',
              key: 'period',
              width: 120,
              render: () => (
                <Select
                  className="select-analysisResult"
                  placeholder="Periodo"
                  options={options}
                  value={period.Type ? period.Type : null}
                  customStyle={{ pointerEvents: 'none' }}
                />
              ),
            },
            {
              title: 'Validación',
              dataIndex: 'positive',
              key: 'positive',
              width: 180,
              render: () => (
                <div className="column-validation">
                  <div className="validation-analysisResult">
                    {Number.isInteger(positive) && positive !== 0 && (
                      <>
                        <CustomIcon className="margin-validation" type="check" />
                        <span className="margin-validation">{positive}</span>
                      </>
                    )}
                    {Number.isInteger(negative) && negative !== 0 && (
                      <>
                        <CustomIcon className="margin-validation" type="cross" />
                        <span>{negative}</span>
                      </>
                    )}
                  </div>
                </div>
              ),
            },
            {
              title: 'Acciones',
              key: 'analyzeValidation',
              dataIndex: 'analyzeValidation',
              width: 100,
              render: () =>
                Number.isInteger(negative) &&
                negative !== 0 &&
                !isEmptyArray(trackingEmail) && (
                  <Tooltip placement="bottom" title="E-Mails a responsables">
                    <img
                      src={`${pathServer.PATH_ICONS}ic_send.svg`}
                      onClick={() =>
                        showSendEmailModal({
                          trackingEmail,
                          fileName,
                          analyzeDate,
                          id,
                        })
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                ),
            },
          ];
          return (
            <div className="container-table" key={id}>
              <Table
                columns={columns}
                dataSource={[
                  {
                    fileName,
                    positive,
                    negative,
                    analyzeUrl,
                    period,
                    trackingEmail,
                    analyzeDate,
                    id,
                  },
                ]}
                size="middle"
                scroll={{ x: 'calc(700px)' }}
                className="table-tracking"
                pagination={false}
              />
              <Divider className="divider-finishedLoad" />
            </div>
          );
        }
      )}
    </div>
  );
};

RowAnalysisResult.displayName = 'RowLoading';

RowAnalysisResult.defaultProps = {
  files: [],
  options: [],
};

RowAnalysisResult.propTypes = {
  files: Proptypes.array,
  options: Proptypes.array,
};

export default RowAnalysisResult;
