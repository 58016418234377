export const pathServer = {
  PATH_ICONS: `${process.env.PUBLIC_URL}/icons/`,
  PATH_IMG: `${process.env.PUBLIC_URL}/img/`,
};
export const Icons = {
  successfull: {
    url: `${pathServer.PATH_ICONS}ic_successfull.svg`,
  },
  upload: {
    url: `${pathServer.PATH_ICONS}ic_upload.svg`,
  },
  loading: {
    url: `${pathServer.PATH_ICONS}ic_loading.svg`,
  },
  trash: {
    url: `${pathServer.PATH_ICONS}ic_trash.svg`,
  },
  download: {
    url: `${pathServer.PATH_ICONS}ic_download.svg`,
  },
  download2: {
    url: `${pathServer.PATH_ICONS}ic_download2.svg`,
  },
  alert: {
    url: `${pathServer.PATH_ICONS}ic_alert.svg`,
  },
  error: {
    url: `${pathServer.PATH_ICONS}ic_error.svg`,
  },
  send: {
    url: `${pathServer.PATH_ICONS}ic_send.svg`,
  },
  cross: {
    url: `${pathServer.PATH_ICONS}ic_cross.svg`,
  },
  check: {
    url: `${pathServer.PATH_ICONS}ic_check.svg`,
  },
  cancel: {
    url: `${pathServer.PATH_ICONS}ic_cancel.svg`,
  },
  question: {
    url: `${pathServer.PATH_ICONS}ic_question.svg`,
  },
  uploadFiles: {
    url: `${pathServer.PATH_ICONS}ic_upLoadFiles.svg`,
  },
  tracking: {
    url: `${pathServer.PATH_ICONS}ic_tracking.svg`,
  },
  reports: {
    url: `${pathServer.PATH_ICONS}ic_reports.svg`,
  },
  dashboard: {
    url: `${pathServer.PATH_ICONS}ic_dashboard.svg`,
  },
  seeEmailTracking: {
    url: `${pathServer.PATH_ICONS}ic_seeEmailTracking.svg`,
  },
  editEmailTracking: {
    url: `${pathServer.PATH_ICONS}ic_editEmailTracking.svg`,
  },
  sendEmailTracking: {
    url: `${pathServer.PATH_ICONS}ic_sendEmailTracking.svg`,
  },
  seeTracking: {
    url: `${pathServer.PATH_ICONS}ic_seeTracking.svg`,
  },
  downloadAttachments: {
    url: `${pathServer.PATH_ICONS}ic_downloadAttachments.svg`,
  },
  newAction: {
    url: `${pathServer.PATH_ICONS}ic_newAction.svg`,
  },
  checkNotification: {
    url: `${pathServer.PATH_ICONS}ic_checkNotification.svg`,
  },
  default: {
    url: `${pathServer.PATH_ICONS}ic_email.svg`,
  },
  spinner: {
    url: `${pathServer.PATH_ICONS}ic_spinner.svg`,
  },
  logout: {
    url: `${pathServer.PATH_ICONS}ic_logout.svg`,
  },
  arrow: {
    url: `${pathServer.PATH_ICONS}ic_arrow.svg`,
  },
  check2: {
    url: `${pathServer.PATH_ICONS}ic_check2.svg`,
  },
  lock: {
    url: `${pathServer.PATH_ICONS}ic_lock.svg`,
  },
  rocket: {
    url: `${pathServer.PATH_ICONS}ic_rocket.svg`,
  },
  clip: {
    url: `${pathServer.PATH_ICONS}ic_clip3.svg`,
  },
  calendar: {
    url: `${pathServer.PATH_ICONS}ic_calendar.svg`,
  },
};

export const FIELD_NEXTTIME_LOGIN = 'nextTime';
export const ERROR_LOGIN = 'Credenciales Incorrectas';
