export const head = [
  {
    title: 'Archivo',
    width: 2,
  },
  {
    title: 'Nombre de Archivo',
    width: 6,
  },
  {
    title: '% de Carga de Archivo',
    width: 15,
  },
];

export const head2 = [
  {
    title: 'Archivo',
    width: 2,
  },
  {
    title: 'Nombre de Archivo',
    width: 6,
  },
  {
    title: '% de Carga de Archivo',
    width: 14,
  },
  {
    title: 'Acción',
    width: 2,
  },
];

export const files = [
  { name: 'Seguimiento mensual Enero1.xls' },
  { name: 'Seguimiento trimestral Ene-Mar.xls' },
  { name: 'Seguimiento mensual Abril1.xls' },
];

export const failedhead = [
  {
    title: 'Archivo',
    width: 2,
  },
  {
    title: 'Nombre de Archivo',
    width: 6,
  },
  {
    title: '% de Carga de Archivo',
    width: 12,
  },
  {
    title: 'Acciones',
    width: 4,
  },
];
export const resultFailed = [
  {
    title: 'Archivo',
    width: 2,
  },
  {
    title: 'Nombre de Archivo',
    width: 8,
  },
  {
    title: 'Periodo',
    width: 6,
  },
  {
    title: 'Validación',
    width: 6,
  },
  {
    title: 'Acciones',
    width: 2,
  },
];
export const filesEmail = [
  {
    sendNumber: '1°',
    fileName: 'Seguimiento mensual Enero1.xsl',
    subject: 'Seguimiento - Reporte Mensual Abril 2022',
    reports: '6',
    // send: true,
  },
  {
    sendNumber: '2°',
    fileName: 'Seguimiento mensual Febrero.xsl',
    subject: 'Seguimiento - Reporte Mensual Febrero 2022',
    reports: '6',
    // send: true,
  },
];

export const options = [
  { value: 'Mensual', label: 'Mensual' },
  { value: 'Trimestral', label: 'Trimestral' },
  { value: 'Semestral', label: 'Semestral' },
  { value: 'Anual', label: 'Anual' },
];

export const initialEmail = {
  date: '19/1/22 16:15',
  title:
    'Correo de PROTECTA S.A. CIA DE SEGUROS - Seguimiento - Reporte Mensual SUCAVE Diciembre 2021',
  sender: 'Rony José Aldave Borja <raldave@protectasecurity.pe>',
  fileName: 'Abr-001-22 Seguimiento mensual Enero1.xls',
  addressee: 'Monica Fabiola Gutierrez Cuentas <mgutierrez@protectasecurity.pe>',
  copy: 'Solange Beaumont Orbegoso <sbeaumont@protectasecurity.pe>, William Diego Ascorra Campos <wascorra@protectasecurity.pe>',
  hiddenCopy: 'Christian Shimabukuro Miyasato <cshimabukuro@protectasecurity.pe>',
  body: `Estimada Mónica,
  Espero que te encuentres bien. He verificado que los reportes mensuales de SUCAVE, correspondientes a tu área, fueron reportados fuera de fecha.`,
  footer:
    'En ese sentido, agradeceré tus comentarios junto a la medida correctiva a implementar para evitar que esta situación vuelva a suceder.',
};
