import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';
import './index.less';
import Layout from 'antd/lib/layout/layout';

const { Option } = Select;

const WSelect = ({
  options,
  propertyLabel,
  propertyValue,
  onSelectValue,
  className,
  customStyle,
  ...props
}) => {
  const handleChange = (value) => {
    onSelectValue(value);
  };

  return (
    <Layout className="select-container select-component">
      <Select
        defaultValue={0}
        showSearch
        placeholder
        style={{ width: '100%', ...customStyle }}
        onChange={handleChange}
        {...props}
      >
        {options.map((item) => {
          return (
            <Option key={item[propertyValue]} value={item[propertyValue]}>
              {item[propertyLabel]}
            </Option>
          );
        })}
      </Select>
    </Layout>
  );
};

WSelect.propTypes = {
  options: PropTypes.array,
  propertyLabel: PropTypes.string,
  propertyValue: PropTypes.string,
  onSelectValue: PropTypes.func,
  disabled: PropTypes.bool,
};

WSelect.defaultProps = {
  options: [{ value: 0, label: 'Noticias' }],
  propertyLabel: 'label',
  propertyValue: 'value',
  onSelectValue: () => {},
  disabled: false,
};

export default WSelect;
