import React from 'react';
import './index.less';
import Proptypes from 'prop-types';
import { Typography, Button } from 'antd';
import CustomIcon from '../CustomIcon/CustomIcon';

const { Title, Paragraph } = Typography;
const ResultModal = ({
  twoButtons,
  icon,
  title,
  subtitle,
  button,
  buttonActionFirst,
  buttonActionSecond,
  buttonActionThird,
  loading,
}) => {
  return (
    <div className="container-successModal">
      <div className="container-icon-successModal">
        <CustomIcon type={icon} />
      </div>
      <Title level={4} className="title-successModal">
        {title}
      </Title>
      {subtitle !== '' && <Paragraph className="subtitle-successModal"> {subtitle} </Paragraph>}

      {!twoButtons ? (
        <div className="container-bn-successModal">
          <Button className="bn-successModal" type="primary" onClick={buttonActionFirst}>
            {button}
          </Button>
        </div>
      ) : (
        <div className="container-bn-successModal">
          <Button
            className="bn-successModal-twoBottoms"
            type="primary"
            onClick={buttonActionSecond}
            loading={loading}
          >
            Sí
          </Button>
          <Button
            className=" bn-successModal-twoBottoms bn-successModal-no"
            onClick={buttonActionThird}
            disabled={loading}
          >
            No
          </Button>
        </div>
      )}
    </div>
  );
};
ResultModal.displayName = 'ResultModal';

ResultModal.defaultProps = {
  twoButtons: false,
  icon: 'successfull',
  title: 'Proceso de Análisis Satisfactorio',
  subtitle: ' ',
  button: 'Ver Resultados',
  buttonActionFirst: () => {},
  buttonActionSecond: () => {},
  buttonActionThird: () => {},
  loading: false,
};

ResultModal.propTypes = {
  twoButtons: Proptypes.bool,
  icon: Proptypes.string,
  title: Proptypes.string,
  subtitle: Proptypes.string,
  button: Proptypes.string,
  buttonActionFirst: Proptypes.func,
  buttonActionSecond: Proptypes.func,
  buttonActionThird: Proptypes.func,
  loading: Proptypes.bool,
};

export default ResultModal;
