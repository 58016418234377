/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import { Divider, Typography, Input } from 'antd';
import { pathServer } from '../../utilities/Constant';

import CustomIcon from '../CustomIcon/CustomIcon';
import { dateGMT } from '../../utilities/Functions';

import './index.less';

const { Paragraph } = Typography;
const { TextArea } = Input;
const SentCommunDetail = ({ onCancel, isEditable, currentEmail, handleChange }) => {
  const [email, setEmail] = useState({});

  const changeEmail = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setEmail(currentEmail);
  }, [currentEmail]);

  return (
    <div className="container-SentCommunDetail">
      <CustomIcon
        className="cancel-sentCommunModal"
        type="cancel"
        onClick={() => {
          onCancel();
          if (isEditable) handleChange(email);
        }}
      />
      <header style={isEditable ? { marginBottom: '7px' } : { marginBottom: '14.5px' }}>
        <div className="container-header-title">
          <span className="header-timeEmail">{dateGMT(email.Date, 'DD/MM/YY HH:mm')}</span>
          <span className="header-title">
            Correo de PROTECTA S.A. CIA DE SEGUROS -{email.Subject}
          </span>
        </div>
        <div className="container-header-title">
          <img className="header-logo" src={`${pathServer.PATH_IMG}logo_login.png`} alt="logo" />
          <span className="header-timeEmail">{email.FROM}</span>
        </div>
        <Divider className="header-divider" />
        <div
          className={isEditable && ' isEditable-header-fileName'}
          style={isEditable && { marginLeft: '-15px' }}
        >
          {isEditable ? (
            <Input
              className="isEditable-input-filename"
              value={email.Subject}
              name="Subject"
              onChange={(e) => changeEmail(e)}
            />
          ) : (
            <Paragraph className="header-fileName">{email.Subject} </Paragraph>
          )}
        </div>
        <Divider className="header-divider" />
      </header>
      <body>
        <div
          className={isEditable ? 'isEditable-container-email-head' : 'container-email-head'}
          style={isEditable && { marginLeft: '20px' }}
        >
          {isEditable ? (
            <Input
              className="isEditable-input-body"
              name="FROM"
              value={email.FROM}
              onChange={(e) => changeEmail(e)}
              disabled
            />
          ) : (
            <p className="email-head"> {email.FROM}</p>
          )}
          {isEditable ? (
            <div style={{ display: 'flex' }}>
              <Paragraph className="email-head" style={{ margin: '0 8px 0 0' }}>
                Para:
              </Paragraph>
              <Input
                className="isEditable-input-body"
                value={email.TO}
                name="TO"
                onChange={(e) => changeEmail(e)}
                disabled
              />
            </div>
          ) : (
            <Paragraph className="email-head">Para: {email.TO}</Paragraph>
          )}
          {isEditable && <Divider className="isEditable-headEmailDivider" />}
          {isEditable ? (
            <div style={{ display: 'flex' }}>
              <Paragraph className="email-head" style={{ margin: '0 8px 0 0' }}>
                Cc:
              </Paragraph>
              <Input
                className="isEditable-input-body"
                name="CC"
                value={email.CC}
                onChange={(e) => changeEmail(e)}
              />
            </div>
          ) : (
            <Paragraph className="email-head">Cc: {email.CC}</Paragraph>
          )}
          {isEditable && <Divider className="isEditable-headEmailDivider" />}
          {isEditable ? (
            <div style={{ display: 'flex' }}>
              <Paragraph className="email-head" style={{ margin: '0 8px 0 0' }}>
                Cco:
              </Paragraph>
              <Input
                className="isEditable-input-body"
                name="CCO"
                value={email.CCO}
                onChange={(e) => changeEmail(e)}
              />
            </div>
          ) : (
            <Paragraph className="email-head">Cco: {email.CCO}</Paragraph>
          )}
        </div>
        {isEditable ? (
          <div
            className={isEditable && 'isEditable-container'}
            style={isEditable && { marginLeft: '20px' }}
          >
            <div
              className={isEditable ? ' isEditable-container-email-body' : 'container-email-body'}
            >
              <TextArea
                className="isEditable-text-body"
                rows={4}
                name="Header"
                value={email.Header}
                onChange={(e) => changeEmail(e)}
              />

              <div className="email-body" dangerouslySetInnerHTML={{ __html: email.Table }} />

              <TextArea
                className="isEditable-text-body"
                rows={4}
                name="Footer"
                value={email.Footer}
                onChange={(e) => changeEmail(e)}
              />
            </div>
          </div>
        ) : (
          <div
            className="email-body"
            dangerouslySetInnerHTML={{
              __html: email.Body,
            }}
          />
        )}
      </body>
    </div>
  );
};

export default SentCommunDetail;
