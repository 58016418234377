import React from 'react';
import PropTypes from 'prop-types';
import './index.less';

import { pathServer } from '../../utilities/Constant';

const Loading = ({ className }) => {
  return (
    <div className={`container-loading ${className}`}>
      <img src={`${pathServer.PATH_IMG}loading.gif`} />
    </div>
  );
};

Loading.displayName = 'Loading';

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: null,
};
export default Loading;
