import React from 'react';
import { UploadNotification } from '../components';
import { TypeNotification } from '../components/UploadNotification/constants';

export const showNotification = (notificationType, onClose) => {
  let notification;
  if (!notificationType) {
    return null;
  }
  switch (notificationType) {
    case 'waitValidation':
      notification = (
        <UploadNotification
          type={TypeNotification.one}
          className="uploadNotification"
          text="Un momento por favor… Los archivos se están analizando"
          onClose={onClose}
        />
      );
      break;
    case 'uploadingFiles':
      notification = (
        <UploadNotification
          type={TypeNotification.one}
          className="uploadNotification"
          onClose={onClose}
        />
      );
      break;
    case 'uploadSuccess':
      notification = (
        <UploadNotification
          type={TypeNotification.two}
          className="uploadNotification"
          text="Los archivos se subieron satisfactorialmente"
          onClose={onClose}
        />
      );
      break;
    case 'editableFields':
      notification = (
        <UploadNotification
          type={TypeNotification.three}
          className="uploadNotification"
          text="Los campos del E-mail que se en cuentran resaltados pueden ser editados"
          onClose={onClose}
        />
      );
      break;
    case 'sendingEmail':
      notification = (
        <UploadNotification
          type={TypeNotification.one}
          className="uploadNotification"
          text="Un momento por favor… El E-mail se está enviando a los responsables"
          onClose={onClose}
        />
      );
      break;
    default:
      notification = (
        <UploadNotification
          type={TypeNotification.one}
          className="uploadNotification"
          onClose={onClose}
        />
      );
  }

  return notification;
};
