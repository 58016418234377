import React from 'react';
import { Typography, Row, Col, Tooltip } from 'antd';
import Proptypes from 'prop-types';
import HeadTable from '../HeadTable/HeadTable';
import CustomIcon from '../CustomIcon/CustomIcon';
import Select from '../Select/Select';

import { dateGMT } from '../../utilities/Functions';

import './index.less';

const { Title } = Typography;

const SendEmailModal = ({
  files,
  onCancel,
  onViewEmail,
  onEditEmail,
  onSendEmail,
  loadingSend,
}) => {
  const headEmail = [
    {
      title: 'Envío:',
      width: 2,
    },
    {
      title: 'De:',
      width: 4,
    },
    {
      title: 'Para:',
      width: 4,
    },
    {
      title: 'Con copia a:',
      width: 4,
    },
    {
      title: 'Asunto del correo:',
      width: 5,
    },
    {
      title: 'Reportes:',
      width: 2,
    },
    {
      title: 'Acciones:',
      width: 3,
    },
  ];

  return (
    <div className={`${loadingSend ? 'opacity' : ''} `}>
      <CustomIcon className="cancel-sendEmailModal" type="cancel" onClick={() => onCancel()} />
      <div className="container-sendEmailModal">
        <div className="d-flex justify-content-between">
          <Title className="title-sendEmailModal">
            Enviar E-mail para el archivo: {files.fileName}
          </Title>
          <span className="mr-3">Fecha de envío: {dateGMT(files.analyzeDate, 'DD/MM/YYYY')} </span>
        </div>

        <HeadTable head={headEmail} />
        {files.trackingEmail.map(({ Emails, Date }, index) => (
          <Row key={files.date} className="row-contentEmail">
            <Col span={1} className="column-contentEmail">
              {index + 1}º
            </Col>
            <Col span={4} className="column-select column-contentEmail">
              <Select
                options={[{ label: Emails.From.Email, value: Emails.From.Email }]}
                value={Emails.From.Email}
              />
            </Col>
            <Col span={4} className="column-select column-contentEmail">
              <Select
                options={[{ label: Emails.To.Email, value: Emails.To.Email }]}
                value={Emails.To.Email}
              />
            </Col>
            <Col span={4} className="column-select column-contentEmail">
              <Select
                options={Emails.CC.map((e) => {
                  return { label: e.Email, value: e.Email };
                })}
                value={Emails.CC[0]?.Email}
              />
            </Col>
            <Col span={6} className="column-contentEmail">
              {Emails.Subject}
            </Col>
            <Col span={2} className="column-contentEmail">
              {Emails.Report}
            </Col>
            <Col span={3} className="column-contentEmail">
              <div className="container-actionsEmail">
                <Tooltip placement="bottom" title="Visualizar E-mail">
                  <CustomIcon
                    type="seeEmailTracking"
                    className="icon-actionEmail"
                    onClick={() => onViewEmail({ Date, ...Emails, name: files.name, id: files.id })}
                  />
                </Tooltip>
                {!Emails.Status && (
                  <>
                    <Tooltip placement="bottom" title="Editar E-mail">
                      <CustomIcon
                        type="editEmailTracking"
                        className="icon-actionEmail"
                        onClick={() =>
                          onEditEmail({ Date, ...Emails, name: files.name, id: files.id })
                        }
                      />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Enviar E-mail">
                      <CustomIcon
                        type="sendEmailTracking"
                        className="icon-actionEmail"
                        onClick={() =>
                          onSendEmail({ Date, ...Emails, name: files.name, id: files.id })
                        }
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

SendEmailModal.displayName = 'SendEmailModal';

SendEmailModal.defaultProps = {
  files: {},
  onCancel: () => {},
};

SendEmailModal.propTypes = {
  files: Proptypes.object,
  onCancel: Proptypes.func,
};

export default SendEmailModal;
